import { createMachine, assign, fromPromise } from 'xstate';

interface Thread {
    id: string;
    // Add other properties of a thread here
}

interface ThreadsContext {
    threads: Thread[];
    selectedThreads: Thread[];
    error: string | null;
}

type ThreadsEvent =
    | { type: 'FETCH' }
    | { type: 'TOGGLE_SELECT'; content: Thread }
    | { type: 'RETRY' };

export const threadsMachine = createMachine({
    /** @xstate-layout N4IgpgJg5mDOIC5QBcAWAnMBDCsB0AlhADZgDEAYgKIAqAwgBIDaADALqKgAOA9rAcgI8AdpxAAPRACYAjAE48UgBwypAFgBs6mSxYaNAGhABPRAGYA7FLxy9FgKxz7U+2qln7AX09G0mHPjEPDgEwlBkECJghMIAbjwA1tF+2Lh4QSFhCKHxAMZYgiKsbMVivPyFokgSiBqqijpyLvYtFmpqMkamCGZyGnhmMhYaFhYew0rKat6+GKmBwRCh4WDo6DzoeFzEBQBmGwC2eCkB6YvL2XE8+ZXFpdXlAkJVoJIIddayLE0tre2dJkQQyUeBYFhUMnsWhcMhkZhmIBOaQyEEgZBoAHkAOJYgAyVAA+gBlKj4ug0e7cPhPERiN4WOQWGxSPpyNksxwyNRdaSTRS9ewWOFjcHuBFIhY4NHUejMdhlamVOm1do2Sz2FgapTtNTDHkIFkKMZODR6dTuezwnyIuanVbrdBkABKtCdAE1KSBHkrqm8NKq5OrNSxte09YCECo8Go2WylPHbBpLBpvNbhDxUfBqhKFRVnsqEABaQwR4ug3QVysVrni21pIikXM0l41BBufWWaxyNT2JR2U0aeO1-zI85hJs+17mBwDOTawaW5xQpQdxw2Hva4FKVxKMwp60Ss5SiAT-O+xB9mSg1waWyWJS38H6josPBKJx9ppSdweB-D+Z4PaGynrS56RiwV6apod7go+K4RkM-TGr2UgWN82pgqmnhAA */
    id: 'threads',
    initial: 'idle',
    context: {
        threads: [],
        selectedThreads: [],
        error: null,
    } as ThreadsContext,
    states: {
        idle: {
            on: {
                FETCH: {
                    target: 'loading',
                }
            }
        },
        loading: {
            invoke: {
                src: 'fetchThreads',
                onDone: {
                    target: 'loaded',
                    actions: [
                        assign({
                            threads: ({ context, event }) => {
                                console.log('Fetched threads:', event.output);
                                return event.output
                            },
                        }),"notifySuccess"
                    ]
                },
                onError: {
                    actions: [
                        assign({
                            error: ({ context, event }) => {
                                // console.error('Error fetching threads:', event.error);
                                // context.error = event.error;
                                return context.error;
                            }
                        }),
                    ]
                }
            }
        },
        loaded: {
            on: {
                TOGGLE_SELECT: {
                    actions: [
                        assign({
                            selectedThreads: ({ context, event }) => {
                                if (event.type !== 'TOGGLE_SELECT') return context.selectedThreads;
                                const thread = event.selectedThread;
                                return context.selectedThreads.includes(thread)
                                    ? context.selectedThreads.filter(t => t !== thread)
                                    : [...context.selectedThreads, thread];
                            }
                        }),
                    ]
                },
                FETCH: {
                    target: 'loading',
                }
            }
        },
        error: {
            on: {
                RETRY: {
                    target: 'loading',
                }
            }
        }
    }
}, {
    actions: {
        // logState: ({ context, event }) => {
        //     console.log(`Current state: ${event.type}`);
        // },
        // logTransition: ({ context, event }) => {
        //     console.log(`Transition: ${event.type}`);
        // },
    },
});