// src/App.jsx
import React, { useContext, useMemo } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Profile from "./pages/Profile";
import Threads from "./pages/Threads";
import ThreadsV2 from "./pages/ThreadsV2";
import Insight from "./pages/Insight";
import Login from "./pages/Login";
import Main from "./pages/Main";
import Navbar from "./components/Navbar"; // 네비게이션 바 컴포넌트 추가
import "./App.css"; // CSS 파일 추가
import { AuthProvider, AuthContext } from "./components/AuthContext";
import AuthCheck from "./components/AuthCheck";
import { ErrorProvider } from "./contexts/ErrorContext";
import { SuccessProvider } from "./contexts/SuccessContext";
import { LoadingProvider } from "./contexts/LoadingContext";
function App() {
	return (
		<ErrorProvider>
			<SuccessProvider>
				<AuthProvider>
					<LoadingProvider>
						<Router>
							<AuthCheck />
							<Navbar />
							<div className="container">
								<Routes>
									<Route
										path="/"
										element={
											<Main />
										}
									/>
									<Route
										path="/profile"
										element={
											<Profile />
										}
									/>
									{/* <Route
										path="/insight"
										element={
											<Insight />
										}
									/> */}
									<Route
										path="/threads-v2"
										element={
											<ThreadsV2 />
										}
									/>
									<Route path="/login" element={<Login />} />
								</Routes>
							</div>
						</Router>
					</LoadingProvider>
				</AuthProvider>
			</SuccessProvider>
		</ErrorProvider>
	);
}

export default App;
