import React from "react";
import { Link } from "react-router-dom";
import { OAUTH_URL, CLIENT_ID, REDIRECT_URI, SCOPE, RESPONSE_TYPE } from "./config";

const validateUrl = (url, validOrigins) => {
	try {
		const parsedUrl = new URL(url);
		if (!validOrigins.includes(parsedUrl.origin)) {
			throw new Error("Invalid redirect URL");
		}
		return url;
	} catch (error) {
		console.error("URL validation error:", error);
		return null;
	}
};

const Navbar = ({ isAuthenticated }) => {
	const redirectUrl = validateUrl(`${OAUTH_URL}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}&response_type=${RESPONSE_TYPE}`, ["https://threads.net"]);

	const handleClick = () => {
		try {
			window.location.assign(redirectUrl);
		} catch (error) {
			console.error("Redirection failed:", error);
			// Optionally, provide user feedback or fallback logic here
		}
	};

	return (
		<nav className="navbar">
			<section className="navbar-section">
				{/* <Link to="/" className="btn btn-link">
					Threads
				</Link> */}
				<Link to="/threads-v2" className="btn btn-link">
					Threads
				</Link>
				<Link to="/profile" className="btn btn-link">
					프로필
				</Link>
				{/* <Link to="/insight" className="btn btn-link">
					자동생성
				</Link> */}
			</section>
			<section className="navbar-section">
				<Link onClick={handleClick} className="btn btn-primary">
					로그인
				</Link>
			</section>
		</nav>
	);
};

export default Navbar;
