import React, { createContext, useState, useContext, useCallback } from 'react';

const SuccessContext = createContext();

export const SuccessProvider = ({ children }) => {
    const [success, setSuccess] = useState(null);

    const handleSuccess = useCallback((message) => {
        setSuccess(message);
        setTimeout(() => {
            setSuccess(null);
        }, 3000); // 3초 후 자동으로 사라짐
    }, []);

    return (
        <SuccessContext.Provider value={{ success, handleSuccess }}>
            {success && (
                <div className="success-toast">
                    <p>{success}</p>
                </div>
            )}
            {children}
        </SuccessContext.Provider>
    );
};

export const useSuccess = () => {
    const context = useContext(SuccessContext);
    if (!context) {
        throw new Error('useSuccess must be used within a SuccessProvider');
    }
    return context;
};