import { useMachine } from "@xstate/react";
import { controlPanelMachine } from "../machines/controlPanelMachine";
import { useEffect, useState } from "react";
import { useSuccess } from "../contexts/SuccessContext";
import { useError } from "../contexts/ErrorContext";
import { fromPromise } from 'xstate';
import { useLoading } from "../contexts/LoadingContext";
import './ControlPanelV2.css';
interface Thread {
    id: string;
    text: string;
    link: string;
    perma: string;
}
export const ControlPanelV2: React.FC<{
    selectedThreads: Thread[];
    // handleSuccess: (message: string) => void;
    // handleError: (message: string) => void;
}> = ({ selectedThreads }) => {
    const { handleSuccess } = useSuccess();
    const { handleError } = useError();
    const { loading, setLoading } = useLoading();
    const [state, send] = useMachine(controlPanelMachine.provide({
        actions: {
            notifySuccess: () => handleSuccess("스레드를 성공적으로 불러왔습니다."),
            notifyError: (context) => handleError(context.context.error),
            destroyLoading: () => setLoading(false),
        },
        actors: {
            uploadText: fromPromise(async () => {
            }),
            generateText: fromPromise(async ({ input }) => {
                setLoading(true);
                const saveContentsUrl = "https://api.so-eum.link/selectgen";
                console.log("generateTest INPUT", input)

                const response: any = await fetch(saveContentsUrl, {
                    method: "POST",
                    credentials: "include", // 쿠키 포함
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        topics: selectedThreads.map((thread) => thread.text),
                        keywords: keywords,
                        has_format: input.hasFormat,
                        max_char_count: input.maxCharCount,
                    }),
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error("서버에 데이터를 전송하는 중 문제가 발생했습니다.");
                        }
                        return response.json();
                    })
                return response;
            })

        }
    }), {
        inspect: (state: any) => {
            console.log(state)
        }
    });

    const keywords = state.context.keywords;
    const generatedText = state.context.generatedText;
    const charCount = state.context.charCount;

    const handleKeywordsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        send({ type: "SET_KEYWORDS", keywords: e.target.value });
    };

    const handleGenerate = () => {
        send({ type: "GENERATE" });
    };

    const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        send({ type: "UPDATE_EDITED_TEXT", text: e.target.value });
    };

    const handleUpload = () => {
        send({ type: "UPLOAD" });
    };

    let controlBody;
    useEffect(() => {
        if (selectedThreads.length === 0) {
            send({ type: "RESET" });
        }
    }, [selectedThreads])

    switch (state.value) {
        case "idle":
            controlBody = (
                <>
                    <div className="input-group">
                        <textarea
                            id="keywords"
                            className="textarea"
                            value={keywords}
                            onChange={handleKeywordsChange}
                            rows={3}
                            placeholder="어떤 글을 써볼까요?"
                        ></textarea>
                    </div >
                    <div className="button-group">
                        <div className="format-options">
                            <div className="toggle-switch">
                                <input
                                    type="checkbox"
                                    id="format-toggle"
                                    className="toggle-switch-checkbox"
                                    checked={state.context.hasFormat}
                                    onChange={() => send({ type: "TOGGLE_FORMAT" })}
                                />
                                <label className="toggle-switch-label" htmlFor="format-toggle">
                                    <span className="toggle-switch-inner"></span>
                                    <span className="toggle-switch-switch"></span>
                                </label>
                            </div>
                            <label htmlFor="format-toggle" className="toggle-label">포맷 사용</label>
                            <input
                                type="number"
                                value={state.context.maxCharCount}
                                onChange={(e) => send({ type: "SET_MAX_CHAR_COUNT", count: parseInt(e.target.value, 10) })}
                                placeholder="최대 글자 수"
                                min="1"
                            />
                        </div>
                        <button onClick={handleGenerate} className="primary-button">Shoot! {selectedThreads.length}</button>
                    </div >
                </>
            )
            break;
        case "generated":
            controlBody = (
                <div className="generated-text">
                    <textarea
                        className="textarea"
                        value={generatedText}
                        onChange={handleTextChange}
                        rows={10}
                    />
                    <button className={`primary-button ${charCount > 500 ? 'over-limit' : ''}`} onClick={handleUpload}>SHOOOOOOT!!!!   {charCount}</button>
                </div>
            )
            break;
    }
    return (selectedThreads.length > 0 &&
        <div onLoad={(e) => console.log(e)} className="control-panel">
            <div className="section">
                {controlBody}
            </div>
        </div>
    );
};