import React, { useContext, useEffect, useState, useMemo } from "react";
import { AuthContext } from "./AuthContext";

function AuthCheck() {
    const { login, logout } = useContext(AuthContext);
    const [lastChecked, setLastChecked] = useState(0);

    const checkAuth = useMemo(() => {
        console.log("api call detected: /me API endpoint called");
        return async () => {
            const now = Date.now();
            if (now - lastChecked < 300000) { // 5분 이내에 체크했다면 스킵
                return;
            }

            try {
                const response = await fetch('https://api.so-eum.link/me', {
                    credentials: 'include',
                });
                if (response.ok) {
                    // const userData = await response.json();
                    login();
                } else {
                    logout();
                }
                setLastChecked(now);
            } catch (error) {
                console.error("인증 확인 중 오류 발생:", error);
                logout();
            }
        };
    }, [login, logout, lastChecked]);

    useEffect(() => {
        checkAuth();
        const interval = setInterval(checkAuth, 900000);
        return () => clearInterval(interval);
    }, [checkAuth]);

    return null; // 이 컴포넌트는 UI를 렌더링하지 않습니다
}

export default AuthCheck;