// authReducer.js
export const initialAuthState = {
    isAuthenticated: false,
};

export const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return {
                isAuthenticated: true,
            };
        case "LOGOUT":
            return initialAuthState;
        default:
            return state;
    }
};
