// src/pages/Profile.jsx
import React, { useState, useEffect } from "react";
import "./Threads.css";
const Profile = () => {
	const [profile, setProfile] = useState(null); // 프로필 데이터 상태
	const [loading, setLoading] = useState(true); // 로딩 상태
	const [error, setError] = useState(null); // 에러 상태

	useEffect(() => {
		const apiUrl = import.meta.env.VITE_PROFILE_API_URL || "https://api.so-eum.link/profile";

		fetch(apiUrl, {
			method: "GET",
			credentials: "include", // 쿠키 포함
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("네트워크 응답에 문제가 있습니다.");
				}
				return response.json();
			})
			.then((data) => {
				setProfile(data); // 프로필 데이터 저장
				setLoading(false); // 로딩 완료
			})
			.catch((error) => {
				console.error("오류:", error);
				setError(error.message); // 에러 메시지 저장
				setLoading(false); // 로딩 완료
			});
	}, []);

	if (loading) {
		return (
			<div className="container">
				<div className="loader"></div>
				<p>로딩 중...</p>
			</div>
		);
	}

	if (error) {
		return (
			<div className="error-message">
				<p>오류: {error}</p>
				<button className="btn btn-link" onClick={() => window.location.reload()}>
					다시 시도하기
				</button>
			</div>
		);
	}

	if (!profile) {
		return (
			<div className="container">
				<p>프로필 정보를 불러올 수 없습니다.</p>
			</div>
		);
	}

	return (
		<div className="container">
			<h1>프로필 페이지</h1>
			<div className="card">
				<div className="card-header">
					<div className="card-title h5">
						{profile.name} (@{profile.username})
					</div>
				</div>
				<div className="card-body">
					<img src={profile.threads_profile_picture_url} alt={`${profile.name} 프로필 사진`} className="profile-picture" />
					<p>{profile.threads_biography}</p>
				</div>
			</div>
		</div>
	);
};

export default Profile;
