// src/pages/Profile.jsx
import React, { useState, useEffect } from "react";
import "./Main.css";
const Main = () => {
    return (
        <div className="landing-page">
            <header className="header">
                <div className="container">
                    <h1>쏘는 순간 퍼지는 당신의 이야기, Shooter</h1>
                    <h2>클릭 한 번으로 완성되는 맞춤형 쓰레드, AI로 쏘아올리는 당신만의 콘텐츠</h2>
                    <a href="#cta" className="cta-button">무료로 시작하기</a>
                </div>
            </header>

            <main>
                <section id="problems" className="problems-section">
                    <div className="container">
                        <h2>당신의 고민</h2>
                        <ul>
                            <li>매일 새로운 콘텐츠를 만들어야 하는 부담</li>
                            <li>일관된 톤앤매너 유지의 어려움</li>
                            <li>여러 플랫폼 관리의 피로도</li>
                            <li>시간 대비 낮은 효율</li>
                        </ul>
                        <h3>Shooter의 해결책</h3>
                        <p>AI 기반 올인원 솔루션으로 소셜미디어 콘텐츠를 자동으로 제작하고 관리하세요.</p>
                        <p>쏘고 잊기만 하면 됩니다. 나머지는 Shooter가 알아서 처리합니다.</p>
                    </div>
                </section>

                <section id="testimonials" className="testimonials-section">
                    <div className="container">
                        <h2>고객 후기</h2>
                        <div className="testimonial">
                            <p>"하루 평균 2시간이 걸리던 콘텐츠 작성이 이제는 10분이면 충분해요"</p>
                            <cite>- 김OO 인플루언서</cite>
                        </div>
                        <div className="testimonial">
                            <p>"Shooter가 제안하는 내용이 너무 자연스러워서 놀랐어요"</p>
                            <cite>- 박OO 마케터</cite>
                        </div>
                        <div className="testimonial">
                            <p>"예약 포스팅 기능으로 주말에도 쉴 수 있게 되었습니다"</p>
                            <cite>- 이OO 프리랜서</cite>
                        </div>
                    </div>
                </section>

                <section id="features" className="features-section">
                    <div className="container">
                        <h2>Shooter의 핵심 기능</h2>
                        <div className="feature">
                            <h3>AI 맞춤 작성기</h3>
                            <ul>
                                <li>당신만의 말투 설정</li>
                                <li>원하는 길이와 형식으로 자유롭게</li>
                                <li>한 번의 클릭으로 완성되는 콘텐츠</li>
                            </ul>
                        </div>
                        <div className="feature">
                            <h3>스타일 매칭</h3>
                            <ul>
                                <li>내가 쓴 글 스타일 그대로</li>
                                <li>브랜드 톤앤매너 완벽 유지</li>
                                <li>레퍼런스 기반 맞춤 생성</li>
                            </ul>
                        </div>
                        <div className="feature">
                            <h3>스마트 예약</h3>
                            <ul>
                                <li>최적 시간대 자동 분석</li>
                                <li>원클릭 예약 발행</li>
                                <li>캘린더로 보는 콘텐츠 일정</li>
                            </ul>
                        </div>
                        <div className="feature">
                            <h3>크로스 플랫폼 통합</h3>
                            <ul>
                                <li>인기 게시물 인스타 자동 변환</li>
                                <li>이미지 최적화 기능</li>
                                <li>원클릭 릴스 제작</li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="faq" className="faq-section">
                    <div className="container">
                        <h2>자주 묻는 질문</h2>
                        <div className="faq-item">
                            <h3>Q: Shooter는 어떤 플랫폼을 지원하나요?</h3>
                            <p>A: 현재 쓰레드를 지원하며 향후 인스타그램까지 순차적으로 추가될 예정입니다.</p>
                        </div>
                        <div className="faq-item">
                            <h3>Q: AI가 작성한 내용을 수정할 수 있나요?</h3>
                            <p>A: 네, 모든 내용은 자유롭게 수정 가능하며, 수정된 내용은 AI 학습에 반영되어 더 나은 결과물을 만듭니다.</p>
                        </div>
                        <div className="faq-item">
                            <h3>Q: 예약 포스팅은 몇 개까지 가능한가요?</h3>
                            <p>A: 요금제에 따라 차등 적용되며, 기본적으로 월 100개까지 가능합니다.</p>
                        </div>
                    </div>
                </section>

                <section id="pricing" className="pricing-section">
                    <div className="container">
                        <h2>Shooter 요금제</h2>
                        <div className="pricing-plan">
                            <h3>Basic</h3>
                            <p className="price">월 29,900원</p>
                            <p>월 100개 포스팅</p>
                        </div>
                        <div className="pricing-plan">
                            <h3>Pro</h3>
                            <p className="price">월 49,900원</p>
                            <p>월 300개 포스팅</p>
                        </div>
                        <div className="pricing-plan">
                            <h3>Enterprise</h3>
                            <p className="price">문의</p>
                            <p>무제한 포스팅</p>
                        </div>
                    </div>
                </section>

                <section id="cta" className="cta-section">
                    <div className="container">
                        <h2>지금 가입하면 14일 무료 체험</h2>
                        <p>신용카드 없이 시작하기</p>
                        <p>언제든지 해지 가능</p>
                        <a href="#" className="cta-button">무료로 시작하기</a>
                    </div>
                </section>
            </main>

            <footer className="footer">
                <div className="container">
                    <p>&copy; 2024 Shooter. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default Main;
