import React, { useEffect, useState } from "react";
import { useMachine } from '@xstate/react';
import { threadsMachine } from '../machines/threadsMachine';
import { useSuccess } from "../contexts/SuccessContext";
import { useError } from "../contexts/ErrorContext";
import { ControlPanelV2 } from "../components/ControlPanelV2";
import { fromPromise } from "xstate";

const ThreadsV2 = () => {
	const { handleSuccess } = useSuccess();
	const { handleError } = useError();
	const { loading, setLoading } = useState(false);

	// XState 최신 API 적용
	const [state, send] = useMachine(threadsMachine.provide({
		actions: {
			notifySuccess: () => handleSuccess("스레드를 성공적으로 불러왔습니다."),
			notifyError: (context) => handleError(context.context.error),
			setLoading: (context) => setLoading(context.event.loading),
		},
		actors: {
			fetchThreads: fromPromise(async () => {
				try {
					console.log("call fetchThreads")
					const apiUrl = import.meta.env.VITE_API_URL || "https://api.so-eum.link/posts";
					const response = await fetch(apiUrl, {
						method: "GET",
						credentials: "include",
					});
					if (!response.ok) {
						throw new Error("네트워크 응답에 문제가 있습니다.");
					}
					const data = await response.json();
					// 불필요한 빈 문자열 제거
					return data.filter((thread) => thread.text && thread.text.trim() !== "");
				} catch (error) {
					throw new Error(error.message || "데이터를 불러오는 중 오류가 발생했습니다.");
				}
			}),
		},
	}));

	// 컴포넌트가 로드될 때 FETCH 이벤트 전송
	useEffect(() => {
		if (state.matches('idle')) {
			console.log("call fetch")
			send({ type: "FETCH", loading: true });
		}
	}, [send]);

	// 상태에 따라 UI 렌더링
	if (state.matches('loading')) {
		send({ type: "TOGGLE_LOADING" });
	}

	if (state.matches('error')) {
		return (
			<div>
				<p>에러: {state.context.error}</p>
				<button onClick={() => send({ type: "RETRY" })}>다시 시도</button>
			</div>
		);
	}

	return (
		<div className="container">
			{state.matches('loaded') && <ControlPanelV2
				selectedThreads={state.context.selectedThreads}
			// handleSuccess={handleSuccess}
			// handleError={handleError}
			/>}
			<div className="section">
				<div className="threads-grid">
					{state.context.threads.map((thread) => (
						<div
							key={thread.id}
							className={`thread-card ${state.context.selectedThreads.includes(thread) ? "selected" : ""}`}
							onClick={() => send({ type: "TOGGLE_SELECT", selectedThread: thread })}
						>
							<div className="card-body">
								<p>{thread.text}</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ThreadsV2;