// src/pages/Login.jsx
import React from "react";

const Login = () => {
	return (
		<div className="container">
			<h2>제품 설명 페이지</h2>
			{/* <article content="이렇게 쓰는건가 " /> */}
		</div>
	);
};

export default Login;
