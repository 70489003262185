import React, { createContext, useContext, useReducer } from "react";
import { authReducer, initialAuthState } from './authReducer';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, initialAuthState);

    const login = (user) => {
        dispatch({ type: 'LOGIN' });
    };

    const logout = () => {
        dispatch({ type: 'LOGOUT' });
        document.cookie = "access_token=; Path=/; Domain=.so-eum.link;Expires=Thu, 01 Jan 1970 00:00:00 GMT;";
    };

    return (
        <AuthContext.Provider value={{ state, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    console.log(context);

    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
};
